/* .search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  
  .search-input {
    width: 100%;
    max-width: 600px;
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 50px;
    outline: none;
    transition: all 0.3s ease;
  }
  
  .search-input:focus {
    border-color: #0073e6;
    box-shadow: 0 0 10px rgba(0, 115, 230, 0.1);
  }
  
  .search-button {
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    background-color: #0073e6;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .search-button:hover {
    background-color: #005bb5;
  }
  
  @media (max-width: 768px) {
    .search-container {
      flex-direction: column;
    }
  
    .search-button {
      margin-top: 10px;
      width: 100%;
      max-width: 600px;
    }
  }
   */

   /* .search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  
  .search-input {
    width: 100%;
    max-width: 600px;
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 50px;
    outline: none;
    transition: all 0.3s ease;
  }
  
  .search-input:focus {
    border-color: #0073e6;
    box-shadow: 0 0 10px rgba(0, 115, 230, 0.1);
  }
  
  .search-button {
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    background-color: #0073e6;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .search-button:hover {
    background-color: #005bb5;
  } */
  
  .image-container {
    position: relative;
    height: 500px;
    overflow: hidden;
  }
  
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .image-container:hover .image {
    transform: scale(1.05);
  }
  
  .download-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: none;
    background: rgba(0, 0, 0, 0.6);
    border: none;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .download-button i {
    font-size: 20px;
  }
  
  .download-button:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  
  .image-container:hover .download-button {
    display: block;
  }
  
  @media (max-width: 768px) {
    .search-container {
      flex-direction: column;
    }
  
    .search-button {
      margin-top: 10px;
      width: 100%;
      max-width: 600px;
    }
  }
  .custom-text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px; /* Adjust the font size as needed */
    font-weight: bold; /* To make the text bold */
    display: flex;
    align-items: center; /* Align icon and text vertically */
  }
  
  
/* 
   .search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  
  .search-input {
    width: 100%;
    max-width: 600px;
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 50px;
    outline: none;
    transition: all 0.3s ease;
  }
  
  .search-input:focus {
    border-color: #0073e6;
    box-shadow: 0 0 10px rgba(0, 115, 230, 0.1);
  }
  
  .search-button {
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    background-color: #0073e6;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .search-button:hover {
    background-color: #005bb5;
  }
  
  .image-container {
    position: relative;
    height: 500px;
    overflow: hidden;
  }
  
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .image-container:hover .image {
    transform: scale(1.05);
  }
  
  .download-button {
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
    background: rgba(0, 0, 0, 0.6);
    border: none;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .download-button:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  
  .image-container:hover .download-button {
    display: block;
  }
  
  @media (max-width: 768px) {
    .search-container {
      flex-direction: column;
    }
  
    .search-button {
      margin-top: 10px;
      width: 100%;
      max-width: 600px;
    }
  }
   */

.search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .search-input {
    width: 100%;
    max-width: 600px;
    padding: 12px 20px;
    border: 2px solid #ccc;
    border-radius: 5px 0 0 5px;
    outline: none;
    font-size: 16px;
  }
  
  .search-button {
    padding: 12px 20px;
    border: 2px solid #007bff;
    border-left: none;
    border-radius: 0 5px 5px 0;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  }
  
  .search-button i {
    font-size: 18px;
  }
  
  
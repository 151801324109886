
.sidebar-content {
  font-size: 13px;
}
.sidebar-head {
  font-size: 15px;
}


.vendors-btn {
  font-size: 12px;
}
.vendor-form {
  width: 30%;
}
.api-content {
  font-size: 13.5px;
}

.user-search {
  padding: 10px !important;
}
.user-input {
  width: 280px;
}
.userdate {
  width: 200px;
}


.total-purchase {
  position: absolute;
  margin-left: 370px;
  margin-top: 10px;
}
.amount-purchase {
  position: absolute;
  margin-left: 20px;
}
.amount-head {
  margin-left: 30px;
}
.bg-time {
  border-radius: 10px;
  color: white;
}

.amount-head1 {
  margin-left: 200px;
}
.total-purchase1 {
  margin-left: 350px;
  margin-top: -22px;
}
/* ============================================================================ */



.btn-profile {
  margin-left: 300px;
  margin-top: -70px;
}
.user_account {
  margin-left: 290px;
}
.user_account1 {
  margin-left: 360px;
}
.user_account2 {
  margin-left: 270px;
}
.handlezoom {
  font-size: 40%;
}
.secound-image-zoom {
  margin-left: 65%;
  margin-top: -4%;
}
.secound-image-zoom1 {
  margin-left: 5%;
  margin-top: -2%;
}
.img-icon-register {
  color: white;
}

.logoimg {
  height: 50px;
}
.card-inside {
  /* height: 430px; */
  /* width: 500px; */
}
.input-in {
  padding: 10px;
  width: 410px;
  border-radius: 5px;
  border-color: gray;
  border: solid lightgray;
  font-size: 13px;
  font-family: Helvetica;
}
.btn-in {
  padding: 10px;
  width: 100px;
  font-size: 13px;
  font-family: Helvetica;
  color: white;
  font-weight: bold;
}
.dash-text {
  font-size: 13px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}
.category {
  height: 60px;
  border-radius: 5px;
}
.card-title {
  font-size: 15px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}
.img-vou {
  height: 160px;
  width: 260px;
  margin-top: 3px;
}
.img-voudiv {
  height: 160px;
  width: 260px;
  margin-top: 3px;
}
.btn-vou {
  border: solid 1px green;
  border-radius: 5px;
  height: 25px;
  width: 60px;
}
.btn-voucher {
  border: solid 1px red;
  border-radius: 5px;
  /* height: 40px;
  width: 80px; */
}
.btn-vouch {
  border: solid 1px skyblue;
  border-radius: 5px;
  /* height: 40px;
  width: 80px; */
}
.btn-text {
  font-weight: bold;
  font-size: 10px;
}
.img_add {
  height: 85px;
  width: 85px;
  margin-top: 3px;
}
.all_icon {
  height: 55px;
  width: 55px;
  margin-left: 16px;
  margin-top: 15px;
}
.t-title {
  font-size: 14px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  /* padding: 232px; */
}
.t-text {
  font-size: 14px;
  font-weight: 500;
  font-family: Verdana, Arial, Helvetica, sans-serif;
}
.t-textt {
  font-size: 14px;
  font-weight: bold;
  font-family: Verdana, Arial, Helvetica, sans-serif;
}
.input-cat {
  font-size: 14px;
  height: 40px;
}

.input-sidecat {
  font-size: 14px;
  height: 65px;
}
.hed-card {
  margin-top: -6px;
  margin-left: -8px;
}
.sss {
  margin-top: 20px;
  height: 14px;
  margin-left: 15px;
}
.card-bo {
  border: 0svh;
}
.card-bord {
  height: 60px;
  border-radius: 8px;
  border-left: solid 0.1px lightgray;
  border-bottom: solid 2px lightgray;
  border-right: solid 0.1px lightgray;
  border-top: solid 3px rgb(0, 119, 255);
}
.background {
  background-image: linear-gradient(#cb5eee, #4be1ec);
  height: 100%;
}
.butt-list {
  background-color: white;
  border: solid 1px white;
  color: #007fff;
}


.custom-checkbox input[type="checkbox"]:checked + .custom-checkmark {
  background-color: blue; 
}

.custom-checkbox input[type="checkbox"]:checked + .custom-checkmark:after {
  content: "\2713"; 
  color: white; 
  font-size: 16px; 
  position: absolute;
  top: 2px;
  left: 4px;
}
.fff{
  color: gray;
  font-size: 12px;
  font-weight: 100;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
.INB{
  height: 52px;
}
.DEV{
  border-radius: 5px;
  border-bottom: white;
  border-top: white;
  border-left: white;
  border-right: white;
  height: 20px;
  font-size: 11px;
  font-weight: bolder;
  width:100px;
}
.location{
  font-weight: bold;
}
.float-end {
  float: right;
}
.DEVV{
  border-radius: 5px;
  border-bottom: white;
  border-top: white;
  border-left: white;
  border-right: white;
  height: 25px;
  font-size: 11px;
  font-weight: bolder;
  width:150px;
}
.headerd {
  display: flex;
  justify-content: space-between;
  
  height: 35px;
}

.btn-outline-white {
  background-color: transparent;
  border: 1px solid white;

  padding: 8px 16px;
  cursor: pointer;
  margin-right: 10px; 
}


.btn-outline-white:hover {
  background-color: aliceblue;
  color: #333; 
}
.card-foot {
  height: 20px;

  border-left: solid 0.1px lightgray;
  border-bottom: solid 2px lightgray;
  border-right: solid 0.1px lightgray;
  border-top: solid 3px rgb(0, 119, 255);
}
.holiday{
  height: 250px;
  justify-content: space-between;
}
.card-body hr {
  margin-top: 5px; /* Adjust this value to decrease space */
  margin-bottom: 5px; /* Adjust this value to decrease space */
  padding: 0; /* Remove any default padding */
}
.wat{
  width:70px;
  border-radius: 5px;
  border-bottom: none;
  border-top: none;
  border-left: none;
  border-right: none;
}
.divclass{
    
  font-family: Arial, Helvetica, sans-serif;
border-style: ridge;
border-radius: 4px;

}
.asso{
  color: #0033a1;
  font-size: 20px;
  font-weight: bold;
}
.rename{
  width: 100px;
}
.switch-selector {
  display: flex;
  justify-content: space-between;
  width: 200px; /* Adjust width as needed */
  border: 1px solid #ccc;
  border-radius: 20px;
  overflow: hidden;
}

.option {
  flex: 1;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.option:hover {
  background-color: #f0f0f0;
}

.selected {
  background-color: #0097e6;
  color: white;
  font-weight: bold;
}



.newdiv{
  height: 250px;


}

.img-cover {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 20px; 
}

.jik{
  font-size: 15px;
}
.search-bar {
  border: none;
  border-bottom: 1px solid #000; 
  outline: none;
  padding: 5px;
}

.newdivv{
  height: 180px;


}
.img-covere {
  object-fit: cover;
  height: 100%;
  width: 100%;
  justify-self: center;
  border-radius: 12px;

}

.vouicon{
  height: 30px;
  width: 50px;
  margin-top: auto !important;
}
.stavou{
  border: orangered 1px;
  background-color: rgb(255, 106, 0);
  width: 250px;
}
.homevou{
  color: rgb(255, 106, 0);
}
.gifticon{
  color: rgba(128, 128, 128, 0.903);
}
.gifticon2{
  color: lightgray;
  font-size:30px;
}
.img-cove {

  height: 100px;
  width: 150px;

}
.imageed {
  border: 1px solid lightgray;
  height: 180px;
}

.inpdiv{
  border: 1px solid lightgray;
  height: 45px;
  width: 100%;
  border-radius: 30px;
}
.indiv{
  border: 1px solid lightgray;
  height: 55px;
  width: 100%;
}
.cartdiv{
  border: 1px solid lightgray;
  height: 400px;
  width: 100%;
}
.carddiv{
  border: 1px solid lightgray;
  height: 330px;
  width: 100%;
}
.batadiv{

  height: 30px;
  width: 100%;
}
.ght{
  width: 100%;
}
.batadivv{
background-color:#F5F5F5;
  height: 30px;
  width: 100%;
}
.batadivvv{
  background-color:#F5F5F5;
    height: 45px;
    width: 100%;
  }
  .selected {
    background-color: lightgreen;
  }
  

  .corner {
    max-width: 80% !important;
    /* border: #000 solid 0.01px; */
    border-radius: 12px;

    box-shadow: 5px 0px 10px 3px whitesmoke;
    margin-left: 0px;
    margin-right: 0;
  }
  
  .custom-width {
    width: 70%;
  }
  

  .cardn {
    border: lavender solid 1px;
    box-shadow: 2px 2px 4px 4px lavender;
  }
  

  
  .digit-box-container {
    display: flex;
    justify-content: center;
    align-items: center;
 
  }
  
  .digit-box {
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black; /* Optional: Add border */
    /* box-shadow: 4px 4px aliceblue; */
    font-size: 20px; /* Adjust font size as needed */
    font-weight: 1rem; /* Optional: Make the font bold */
    margin: 5px; /* Adjust spacing as needed */
    text-align: center;
    text-align: center;
  }
  .ffont {
    /* font-family: "Trirong", serif; */
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem; /* Adjust the size as needed */
    font-weight: bold;
    /* background: linear-gradient(45deg, #ff6ec4, #7873f5); */
    /* background: linear-gradient(45deg, purple, #7873f5); */
    background: black;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
  }
  .hrcode{
    border: lavender solid 1px;
    box-shadow: 1px 1px  lavender;
  }

  .d-flex .custom-text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px; /* Adjust the font size as needed */
    font-weight: bold; /* To make the text bold */
    display: flex;
    align-items: center; /* Align icon and text vertically */
  }
  .cta {
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .cta span {
    padding-bottom: 7px;
    letter-spacing: 4px;
    font-size: 14px;
    padding-right: 15px;
    text-transform: uppercase;
  }
  
  .cta svg {
    transform: translateX(-8px);
    transition: all 0.3s ease;
  }
  
  .cta:hover svg {
    transform: translateX(0);
  }
  
  .cta:active svg {
    transform: scale(0.9);
  }
  
  .hover-underline-animation {
    position: relative;
    color: black;
    padding-bottom: 20px;
  }
  
  .hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000000;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .cta:hover .hover-underline-animation:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  




  .notification {
    display: flex;
    flex-direction: column;
    isolation: isolate;
    position: relative;
    width: 24rem;
    height: 6rem;
    background: #29292c;
    border-radius: 1rem;
    overflow: hidden;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 16px;
    --gradient: linear-gradient(to bottom, #2eadff, #3d83ff, #7e61ff);
    --color: #32a6ff
  }
  
  .notification:before {
    position: absolute;
    content: "";
    inset: 0.0625rem;
    border-radius: 0.9375rem;
    background: white;
    z-index: 2
  }
  
  .notification:after {
    position: absolute;
    content: "";
    width: 0.25rem;
    inset: 0.65rem auto 0.65rem 0.5rem;
    border-radius: 0.125rem;
    background: var(--gradient);
    transition: transform 300ms ease;
    z-index: 4;
  }
  
  .notification:hover:after {
    transform: translateX(0.15rem)
  }
  
  .notititle {
    color: var(--color);
    padding: 0.65rem 0.25rem 0.4rem 1.25rem;
    font-weight: 500;
    font-size: 1.1rem;
    transition: transform 300ms ease;
    z-index: 5;
  }
  
  .notification:hover .notititle {
    transform: translateX(0.15rem)
  }
  
  .notibody {
    color: #99999d;
    padding: 0 1.25rem;
    transition: transform 300ms ease;
    z-index: 5;
    display: flex;
  }
  
  .notification:hover .notibody {
    transform: translateX(0.25rem)
  }
  
  .notiglow,
  .notiborderglow {
    position: absolute;
    width: 20rem;
    height: 20rem;
    transform: translate(-50%, -50%);
    background: radial-gradient(circle closest-side at center, white, transparent);
    opacity: 0;
    transition: opacity 300ms ease;
  }
  
  .notiglow {
    z-index: 3;
  }
  
  .notiborderglow {
    z-index: 1;
  }
  
  .notification:hover .notiglow {
    opacity: 0.1
  }
  
  .notification:hover .notiborderglow {
    opacity: 0.1
  }
  
  .note {
    color: var(--color);
    position: fixed;
    top: 80%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 0.9rem;
    width: 75%;
  }
/* .container {
    position: relative;
    width: 500px;
    height: 500px;
  }
   */

   
  .top-left,
  .top-right {
    position: absolute;
    width: 130px;
    height: 50px;

  }
  
  .top-left {
    top: 0;
    left: 0;
    margin-left: 22px !important;
    /* margin-left: 22px; */
margin-top: 10px;
  }
  
  .top-right {
    top: 0;
    right: 0;
    /* margin-right: 30px !important; */
    /* margin-right: px; */
    margin-top: 10px;
  }
  
   .bottom-left {
    position: absolute;
    width: 100%;
/* background-color: white; */
    height: 100px;
    margin-left: 0px;
    bottom: 0;
    left: 0;
 
  } 


  .main-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .main-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .image-div {
      
   /* margin-left: 4px; */
    height: 100px;

    background-color: whitesmoke;
    border-radius: 10px;
    border: solid 1px black;
  }

  .image {
    width: 90%;
    height: 80%;

    object-fit: cover;
    border-radius: 10px;
    margin-left: 2px;
    margin-top: 9px;
    
  }

  .image-70 {
    width: 65%;
  }
  
  
  .bottom-right {
    /* width: 420px; */
    height: 100px;
 
  }
  .bottom-inside {
    border-top-right-radius: 50px; 
    align-items: stretch;
    width: 350px;
    margin-left:-8px ;
    height: 40px;
    background-color: black;
    color: white;
  }
  .bottom-inside1 {
  
    align-items: stretch;

    margin-left:-8px ;
    height: 40px;
    background-color: black;
    color: white;
  }
  .bottom-inside2 {
  
    align-items: stretch;
    /* width: 300px; */
    margin-left:8px ;
    height: 40px;
    background-color: black;
    color: white;
  }
  .para{
    font-size: 11px;
  }
  .bottom-address{
    margin-left:-8px ;
    border-top-right-radius: 15px; 
    /* width: 420px; */
    height: 60px;
    background-color: whitesmoke;
    color: black;
  }
  .bottom-address1{
    margin-left:-8px ;
    /* border-top-right-radius: 15px;  */
    /* width: 420px; */
    height: 60px;
    background-color: whitesmoke;
    color: black;
  }
  .bottom-address2{
    margin-left:8px ;
    /* border-top-right-radius: 15px;  */
    /* width: 420px; */
    height: 60px;
    background-color: whitesmoke;
    color: black;
  }
 .bottom-add{
  height: 18px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
 }
  



.boot{
  border: blue;
  border-radius: 4px;
  
}


.design-container {
  width: 500px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* .design-container .bottom-left {
  flex-grow: 1;
} */


.download-mode {
  width: 385px;
  height: 500px;
  object-fit: cover; /* Maintain aspect ratio and cover container */
}

/* .design-container {
  width: 385px;
  height: 500px;
} */

/* @media screen and (max-width: 414px) {
 
  .design-container {
    width: 90vw; 
    height: auto;
  }
} */


/* .image-container {
  display: flex;
  flex-wrap: nowrap; 
  overflow-x: auto;
  scrollbar-width: none; 
  -ms-overflow-style: none; 
} */

.image-container::-webkit-scrollbar {
  display: none; 
}

.image-card {
  position: relative;
  margin-right: 10px; /* Adjust spacing between cards */
  flex: 0 0 auto; /* Prevent flex items from shrinking */
}

.image-card img {
  width: 200px; /* Adjust image width */
  height: auto;
  cursor: pointer;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust shade color and opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.overlay:hover {
  opacity: 1;
}

.arrow-icon {
  color: #fff; /* Adjust arrow icon color */
  font-size: 24px; /* Adjust arrow icon size */
}
.car{
  border: lightgray solid 1px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 3px lightgray;
}
.menudiv{
  border: lightgrey solid 1px;
  height:100vh;
  box-shadow: 5px 5px 15px 3px lightgray;
}




.category-list {
  list-style: none; /* Remove default list styles */
  padding: 0;
}

.category-list li {
  padding: 10px;
  cursor: pointer;
}

.category-list li:hover {
  background-color: #f2f2f2; /* Change background color on hover */
}

.LOG{
  width: 200px;
  border-radius: 15px;
}
.huu{
border: solid 1px lightgrey;
/* width:100px */
}
.hn{
  border-radius: 50px;
}







.overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Optional: To add a background overlay */
}

.arrow-icon {
  font-size: 2rem;
  color: white;
}


.overlay .creator-info img {
  margin-bottom: 5px;
}
.overlay .creator-info div {
  font-size: 14px;
  font-weight: bold;
}


.remove-icon {
  top: 0;
  left: 0;
  padding: 10px;
  z-index: 10; /* Ensure it is above the overlay */
}

.remove-icon i {
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}
